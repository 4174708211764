import React from "react";
import styles from "./intro.module.scss";
import Button from "components/button/button";
import { arrowIllu, asterisk, star } from "base/SVG";
export default function Intro() {
  return (
    <div className={styles["intro"]}>
      <div className="auto__container">
        <div className={styles["intro__inner"]}>
          <h1>
            <span className={styles["aster"]}>{asterisk}</span>
            <span className={styles["star"]}>{star}</span>
            Bringing Ideas to Life, One Project at a Time!
          </h1>
          <p>
            At Digitsky, we turn your vision into stunning digital experiences
            with seamless website development, standout UI/UX design, and
            branding that speaks your language.
          </p>
          <div className={styles["intro__inner-btns"]}>
            <span>{arrowIllu}</span>
            <Button type={"dark"} text={"See Plans"} onClick={() => {window.location.href = "#plans"}} />
            <Button type={"primary"} text={"Lets Talk"} onClick={() => {window.open("https://calendly.com/louisklimek/digitsky-io-intro-call", "_blank")}} />
          </div>
        </div>
      </div>
    </div>
  );
}
