export const priceStandard = [
  {
    id: "1",
    title: "Web & App Dev",
    type: "Full-Scale Development Care",
    price: "700",
    link: "https://buy.stripe.com/5kA6qSe6Yg1K8fe9AB",
    list: [
      {
        id: "1",
        text: "Weekly updates and bug fixes",
      },
      {
        id: "2",
        text: "Feature development and performance optimizations",
      },
      {
        id: "3",
        text: "2x API and CRM integrations",
      },
      {
        id: "4",
        text: "1x Monthly Security monitoring and backups",
      },
      {
        id: "5",
        text: "1x Monthly Mobile app and web app maintenance",
      },
    ],
  },
  {
    id: "2",
    title: "UI/UX Design",
    type: "Design Experience",
    price: "600",
    link: "https://buy.stripe.com/fZe3eG7IAg1KeDCfZ0",
    list: [
      {
        id: "1",
        text: "Weekly UI/UX updates and refinements",
      },
      {
        id: "2",
        text: "New feature design implementation",
      },
      {
        id: "3",
        text: "1x Monthly UX audit to improve user flow and engagement",
      },
      {
        id: "4",
        text: "1x Monthly Design support and consultations",
      },
    ],
  },
  {
    id: "3",
    title: "Branding",
    type: "Brand Evolution",
    price: "500",
    link: "https://buy.stripe.com/fZe4iK3skeXG7baeUX",
    list: [
      {
        id: "1",
        text: "1x Brand guidelines and collateral (social media, digital assets)",
      },
      {
        id: "2",
        text: "1x Seasonal campaign design assets",
      },
      {
        id: "3",
        text: "Ongoing brand consultancy and support",
      },
    ],
  },
  {
    id: "4",
    title: "Digital Marketing",
    type: "Digital Impact",
    price: "600",
    link: "https://buy.stripe.com/28oaH80g88zi1QQbIM",
    list: [
      {
        id: "1",
        text: "Weekly SEO optimization and keyword tracking",
      },
      {
        id: "2",
        text: "Social media management and content creation",
      },
      {
        id: "3",
        text: "Weekly performance reports and analytics",
      },
      {
        id: "4",
        text: "2x Email marketing campaigns and automation setup",
      },
    ],
  },
];
export const pricePro = [
  {
    id: "1",
    title: "Web & App Dev",
    type: "Full-Scale Development Care",
    price: "1400",
    link: "https://buy.stripe.com/fZe7uW7IA9Dmanm28d",
    list: [
      {
        id: "1",
        text: "Bi-Weekly updates and bug fixes",
      },
      {
        id: "2",
        text: "Feature development and performance optimizations",
      },
      {
        id: "3",
        text: "4x API and CRM integrations",
      },
      {
        id: "4",
        text: "1x Weekly Security monitoring and backups",
      },
      {
        id: "5",
        text: "1x Weekly Mobile app and web app maintenance",
      },
    ],
  },
  {
    id: "2",
    title: "UI/UX Design",
    type: "Design Experience",
    price: "1200",
    link: "https://buy.stripe.com/5kA2aC5AsbLugLKeV0",
    list: [
      {
        id: "1",
        text: "Weekly UI/UX updates and refinements",
      },
      {
        id: "2",
        text: "New feature design implementation",
      },
      {
        id: "3",
        text: "1x Weekly UX audit to improve user flow and engagement",
      },
      {
        id: "4",
        text: "1x Weekly Design support and consultations",
      },
    ],
  },
  {
    id: "3",
    title: "Branding",
    type: "Brand Evolution",
    price: "1000",
    link: "https://buy.stripe.com/5kA3eG2og3eY1QQ4gn",
    list: [
      {
        id: "1",
        text: "2x Brand guidelines and collateral (social media, digital assets)",
      },
      {
        id: "2",
        text: "2x Seasonal campaign design assets",
      },
      {
        id: "3",
        text: "Ongoing brand consultancy and support",
      },
    ],
  },
  {
    id: "4",
    title: "Digital Marketing",
    type: "Digital Impact",
    price: "1200",
    link: "https://buy.stripe.com/28o6qS3skcPy7baeUU",
    list: [
      {
        id: "1",
        text: "Bi-Weekly SEO optimization and keyword tracking",
      },
      {
        id: "2",
        text: "Social media management and content creation",
      },
      {
        id: "3",
        text: "Bi-Weekly performance reports and analytics",
      },
      {
        id: "4",
        text: "4x Email marketing campaigns and automation setup",
      },
    ],
  },
];
