import React from "react";
import Team from "./sections/team/team";
import Faq from "./sections/faq/faq";
import Portfolio from "./sections/portfolio/portfolio";
import Plans from "./sections/plans/plans";
import Price from "./sections/price/price";
import Process from "./sections/process/process";
import Intro from "./sections/intro/intro";
import Partners from "./sections/partners/partners";
import Services from "./sections/services/services";

export default function Home() {
  return (
    <>
      <div className="anchor" id="top"></div>

      <Intro />
      <Partners />
      <div className="anchor" id="services"></div>
      <Services />
      <div className="anchor" id="process"></div>
      <Process />
      <Price />
      <div className="anchor" id="plans"></div>
      <Plans />
      <div className="anchor" id="portfolio"></div>
      <Portfolio />
      <div className="anchor" id="faq"></div>
      <Faq />
      {/*<Team />*/}
      <div className="anchor" id="contact"></div>
    </>
  );
}
