import React from "react";
import styles from "./heading.module.scss";
import classNames from "classnames";
export default function Heading({ type, title, text }) {
  return (
    <div className={classNames(styles["heading"], styles?.[type])}>
      <h2>{title}</h2>
      <p>{text}</p>
    </div>
  );
}
